
// 引入vue
import Vue from 'vue'
// 引入vue-router
import VueRouter from 'vue-router'

import NProgress from "nprogress";
import 'nprogress/nprogress.css'
// 注入vuerouter
Vue.use(VueRouter)

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const test = [
    {
        path: '/type',
        name: '考试种类',
        component: () => import('@/views/examination/type.vue')
    },
    {
        path: '/guide',
        name: '考试引导',
        component: () => import('@/views/examination/guide.vue')
    },
    {
        path: '/chapter',
        name: '考试章节',
        component: () => import('@/views/examination/chapter.vue')
    },
    {
        path: '/article',
        name: '考试文章',
        component: () => import('@/views/examination/article.vue')
    },
    // {
    //     path: '/problem',
    //     name: '朗思百科与常见问题',
    //     component: () => import('@/views/examination/problem.vue')
    // },
]
const problem = [
    {
        path: '/problem',
        name: '朗思百科与常见问题',
        component: () => import('@/views/examination/problem.vue')
    },
]
// 课程管理
const course = [
    {
        path: '/courseManagement',
        name: 'courseManagement',
        component: () => import('@/views/courseManagement/courseManagement.vue'),
        meta: {
            title: '课程管理'
        }
    },
    {
        path: '/sectionManagement',
        name: 'sectionManagement',
        component: () => import('@/views/courseManagement/sectionManagement.vue'),
        meta: {
            title: '章节管理'
        }
    },
    {
        path: '/courseDetailManagement',
        name: 'courseDetailManagement',
        component: () => import('@/views/courseManagement/courseDetailManagement.vue'),
        meta: {
            title: '详情管理'
        }
    },
]

// spu sku 商品管理页
const spusku = [
    {
        path: '/CommoditySpu',
        name: 'CommoditySpu',
        component: () => import('@/views/CommodityManagement/CommoditySpu.vue'),
        meta: {
            title: '商品管理spu'
        }
    },
    {
        path: '/CommoditySku',
        name: 'CommoditySku',
        component: () => import('@/views/CommodityManagement/CommoditySku.vue'),
        meta: {
            title: '商品管理sku'
        }
    },
]

// 活动管理
const activ = [
    {
        path: '/activity',
        name: 'activity',
        component: () => import('@/views/ActivityManagement/activity.vue'),
        meta: {
            title: '活动页'
        }
    },
    {
        path: '/promotion',
        name: 'promotion',
        component: () => import('@/views/ActivityManagement/promotion.vue'),
        meta: {
            title: '促销页'
        }
    },
]

// 优惠券
const coupon = [
    {
        path: '/Couponletter',
        name: 'Couponletter',
        component: () => import('@/views/Coupon/Couponletter.vue'),
        meta: {
            title: '优惠券'
        }
    },
    {
        path: '/Couponuser',
        name: 'Couponuser',
        component: () => import('@/views/Coupon/Couponuser.vue'),
        meta: {
            title: '用户优惠券'
        }
    },
]

// 试题管理
const TestQuestionManagement = [
    {
        path: '/choiceQuestion',
        name: 'choiceQuestion',
        component: () => import('@/views/ExaminationPaperManagement/TestQuestionManagement/choiceQuestion.vue')
    },
    {
        path: '/fillsUpTopic',
        name: 'fillsUpTopic',
        component: () => import('@/views/ExaminationPaperManagement/TestQuestionManagement/fillsUpTopic.vue')
    },
    {
        path: '/inLineWithQuestion',
        name: 'inLineWithQuestion',
        component: () => import('@/views/ExaminationPaperManagement/TestQuestionManagement/inLineWithQuestion.vue')
    },
    {
        path: '/judge',
        name: 'judge',
        component: () => import('@/views/ExaminationPaperManagement/TestQuestionManagement/judge.vue')
    },
    {
        path: '/QuestionAnswer',
        name: 'QuestionAnswer',
        component: () => import('@/views/ExaminationPaperManagement/TestQuestionManagement/QuestionAnswer.vue')
    },
    {
        path: '/articleEntry',
        name: 'articleEntry',
        component: () => import('@/views/ExaminationPaperManagement/TestQuestionManagement/articleEntry.vue')
    },
    {
        path: '/writingManagement',
        name: 'writingManagement',
        component: () => import('@/views/ExaminationPaperManagement/TestQuestionManagement/writingManagement.vue')
    },

]

//写作管理
const WritingModule = [
    {
        path: '/WritingModule',
        name: 'WritingModule',
        component: () => import('@/views/WritingModule/WritingModule.vue')
    },
    // {
    //     path: '/AnalysisAdd',
    //     name: 'AnalysisAdd',
    //     component: () => import('@/views/WritingModule/AnalysisAdd.vue')
    // },
    {
        path: '/analysisList',
        name: 'analysisList',
        component: () => import('@/views/WritingModule/analysisList.vue')
    },
    {
        path: '/viewList',
        name: 'viewList',
        component: () => import('@/views/WritingModule/viewList.vue')
    },
    {
        path: '/essayAnalyisisList',
        name: 'essayAnalyisisList',
        component: () => import('@/views/WritingModule/essayAnalyisisList.vue')
    },
    {
        path: '/essayList',
        name: 'essayList',
        component: () => import('@/views/WritingModule/essayList.vue')
    },
    

]

//口语管理
const OralModule = [
    {
        path: '/OralModule',
        name: 'OralModule',
        component: () => import('@/views/OralModule/OralModule.vue')
    },
    {
        path: '/chuantiAnswer',
        name: 'chuantiAnswer',
        component: () => import('@/views/OralModule/chuantiAnswer.vue')
    },
    {
        path: '/chuantiThink',
        name: 'chuantiThink',
        component: () => import('@/views/OralModule/chuantiThink.vue')
    },
    {
        path: '/topicDetail',
        name: 'topicDetail',
        component: () => import('@/views/OralModule/topicDetail.vue')
    },
    {
        path: '/TopicRecord',
        name: 'TopicRecord',
        component: () => import('@/views/OralModule/TopicRecord.vue')
    },
    

]
//拼团模块
const GroupBuying = [
    {
        path: '/GroupBuying',
        name: 'GroupBuying',
        component: () => import('@/views/GroupBuying/GroupBuying.vue')
    },
    {
        path: '/addGroupBuy',
        name: 'addGroupBuy',
        component: () => import('@/views/GroupBuying/addGroupBuy.vue')
    },
    {
        path: '/showGroupBuy',
        name: 'showGroupBuy',
        component: () => import('@/views/GroupBuying/showGroupBuy.vue')
    },
    
    

]
//权限管理
const AuthManagement = [
    {
        path: '/AuthManagement',
        name: 'AuthManagement',
        component: () => import('@/views/AuthManagement/AuthManagement.vue')
    },
    

]
//角色管理
const RoleManagement = [
    {
        path: '/RoleManagement',
        name: 'RoleManagement',
        component: () => import('@/views/RoleManagement/RoleManagement.vue')
    },
    

]
//资源管理
const Resource = [
    {
        path: '/Resource',
        name: 'Resource',
        component: () => import('@/views/Resource/Resource.vue')
    },
    

]

// 传入路由相关信息
const routes = [

    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login')
    },
    {
        path: '/main',//路径
        name: 'Main',//名字 main路由的父组件
        component: () => import('@/views/Main.vue'),//按需引入页面路径
        redirect: '/home',
        children: [//嵌套路由子组件路由
            {
                path: '/home',
                name: '/home',
                component: () => import('@/views/Home')
            },
            {
                path: '/user',
                name: 'user',
                component: () => import('@/views/User')
            },
            {
                path: '/mall',
                name: 'mall',
                component: () => import('@/views/mall')
            },
            
            {
                path: '/teacherManagment',
                name: 'teacherManagment',
                component: () => import('@/views/teacherManagment/teacherManagment.vue'),
                meta: {
                    title: '老师管理'
                }
            },
            {
                path: '/examinationPaperManagement',
                name: 'examinationPaperManagement',
                component: () => import('@/views/ExaminationPaperManagement/ExaminationPaperManagement.vue'),
                meta: {
                    title: '试卷管理'
                }
            },
            {
                path: '/orderManagment',
                name: 'orderManagment',
                component: () => import('@/views/orderManagment/orderManagment.vue'),
                meta: {
                    title: '订单管理'
                }
            },
            {
                path: '/UserManagment',
                name: 'UserManagment',
                component: () => import('@/views/UserManagment/UserManagment.vue'),
                meta: {
                    title: '用户管理'
                },

            },
            {
                path: '/userDetail',
                name: 'userDetail',
                component: () => import('@/views/UserManagment/userDetail.vue'),
                meta: {
                    title: '用户详情'
                },
            },
            
            ...course,
            ...spusku,
            ...test,
            ...activ,
            ...coupon,
            ...TestQuestionManagement,
            ...problem,
            ...WritingModule,
            ...OralModule,
            ...AuthManagement,
            ...RoleManagement,
            ...GroupBuying,
            ...Resource
        ]
    },


]

// 配置路由
// 定义变量router 让他在下边进行对外暴露
const router = new VueRouter({
    mode: 'history',//配置路由匹配模式
    routes//将路由相关信息传入配置
})
router.beforeEach(async (to, from, next) => {
    // start progress bar
    NProgress.start()
    const hasToken = localStorage.getItem('back_token')
    if (hasToken) {
        if (to.path === '/login') {
            next({ path: '/home' })
            NProgress.done()
        } else {
            next()
            NProgress.done()
        }
    } else {
        if (to.path !== '/login') {
            next({ path: '/login' })
            NProgress.done()
        } else {
            next()
        }

    }
})

router.afterEach(() => {
    // finish progress bar
    NProgress.done()
})
// 对外进行暴露
export default router