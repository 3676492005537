<template>
  <div id="app" style="height: 100%;">
    <!-- 注册路由组件 -->
    <router-view></router-view>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App'
  // components: {
  //   HelloWorld
  // }
}
</script>

<style scoped>

</style>
