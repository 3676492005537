// 模拟接口返回
import Mock from 'mockjs'

// 引入回调函数
import homeApi from './mockServeData/home'
import userApi from './mockServeData/user'
import permissionApi from './mockServeData/permission' 

// mock对应拦截器的规则
Mock.mock('./home/getData',homeApi.getStatisticalData)//Mock.mock()方法   拦截的时候可以写一个确切的地址，也可以写一个匹配正则的方法  同时接收一个回调函数
Mock.mock(/user\/add/,'post',userApi.createUser)
Mock.mock(/user\/edit/,'post',userApi.updateUser)
Mock.mock(/user\/getUser/,'get',userApi.getUserList)
Mock.mock(/user\/del/,'get',userApi.deleteUser)
Mock.mock(/permission\/getMenu/,'post',permissionApi.getMenu)