//定义state属性
export default {
    state: {
        isCollapse:false,
        // 用于渲染面包屑
        // 定义一个初始数据，用于存储每次点击菜单，菜单所对应的路由数据
        tabsList:[
            {//定义初始数据
                path:'/',//路径
                name:'home',
                label:'首页',
                icon:'home'
            }
        ],
        currentMenu:null,//面包屑的高亮显示初始值   用于记录当前选中的路由是哪一项
    },
    mutations: {
        collapseMenu(state){
            state.isCollapse = !state.isCollapse
        },
        // 用于改变tabsList中的初始数据
        selectMenu(state,val){
            if(val.name !=='home'){//判断当前点击的菜单是否为首页
                // 如果不是首页就给 state定义的currentMenu进行赋值
                state.currentMenu = val
                // 拿到当前选中项的索引 //判断当前选中的索引是否存在
                const result = state.tabsList.findIndex(item=>item.name === val.name)
                if(result === -1){ //如果不存在
                        state.tabsList.push(val)
                }
            }else{//如果当前选中的是首页的话
                state.currentMenu=null //将当前选中的标识重置
            }
        },
        // 修改数据源mutations
		closeTag(state,val){
            const result= state.tabsList.findIndex(item=>item.name === val.name)
            state.tabsList.splice(result,1)
        }
    }
}