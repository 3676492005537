const toNum = function (n) {
	if (!n) return 0
	let newnum = ''
	if (n < 1000) {
		return n
	} else if (n >= 1000 && n < 10000) {
		return newnum = (n / 1000).toFixed(2) + 'k'
	} else if (n >= 10000 && n < 100000) {
		return newnum = (n / 10000).toFixed(2) + 'w'
	} else if (n >= 100000) {
		return newnum = 10 + 'w+'
	}

}

const durationTrans = function (a) {
	var b = ""
	var h = parseInt(a / 3600),
		m = parseInt(a % 3600 / 60),
		s = parseInt(a % 3600 % 60);
	if (h > 0) {
		h = h < 10 ? '0' + h : h
		b += h + ":"
	}
	m = m < 10 ? '0' + m : m
	s = s < 10 ? '0' + s : s
	b += m + ":" + s
	return b;
}
const toPrice = function (price) {
	if (!price) return
	return '￥' + price.toFixed(2)
}
const publisher = function (num) {
	if (num == 0) return '官方'
	if (num == 1) return '第三方'
	if (num == 2) return '我方'
}
const formatDate = function (time) {
	if (!time) return ''
	time = new Date(time)
	var year = time.getFullYear()
	var month = time.getMonth() + 1
	var day = time.getDate()
	var hour = time.getHours();
	var minute = time.getMinutes();
	return year + '-' + formatNumber(month) + '-' + formatNumber(day) + ' ' + formatNumber(hour) + ':' + formatNumber(minute)
}
const formatNumber = function (n) {
	n = n.toString()
	return n[1] ? n : 0 + n
}
const phoneNum = function (phoneNumber) {
	if (!phoneNumber) return ''
	return phoneNumber.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
}
export default {
	toNum,
	toPrice,
	formatDate,
	phoneNum,
	durationTrans,
	publisher
}