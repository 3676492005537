// 接口请求文件

// 引入二次封装axios的工具类
import http from './axios'

export default class Api {
	// 获取所有考试信息
	static async exam(typeId) {
		return await http.get(`exam/all/${typeId}`)
	}
	/* =====================================================试卷管理========================================= */
	// 添加试卷
	static addExam(data) {
		return http.post('/exam/admin/add', data)
	}
	// 修改试卷
	static modifyExam(data) {
		return http.put('/exam/admin/update', data)
	}
	// 获取所有试卷
	static getExamAll() {
		return http.get('/exam/admin/all')
	}
	// 根据类型获取所有试卷
	static getExamType(typeId) {
		return http.get(`/exam/admin/all/${typeId}`)
	}
	// 选择题
	// 分页获取所有的选择题
	static getChoiceList(pageNum) {
		return http.get(`/exam/admin/all/single_options/page/${pageNum}`)
	}
	// 根据id获取所有选择题
	static getExamIdChoice(examId, pageNum) {
		return http.get(`/exam/admin/all/single_options/${examId}/${pageNum}`)
	}
	// 获取一个选择题信息
	static getChoiceDetail(id) {
		return http.get(`/exam/admin/single_options/${id}`)
	}
	// 添加选择题
	static addChoice(data) {
		return http.post('/exam/admin/single_options', data)
	}
	// 修改选择题
	static modifyChoice(data) {
		return http.put('/exam/admin/single_options', data)
	}
	// 删除选择题
	static deleteChoice(id) {
		return http.delete(`/exam/admin/single_options/${id}`)
	}
	// 获取所有问答题
	static getFillAll(pageNum) {
		return http.get(`/exam/admin/all/question_and_answer/page/${pageNum}`)
	}
	// 分页根据试卷id获取所有的问答题
	static getFillId(examId) {
		return http.get(`/exam/admin/all/question_and_answer/${examId}`)
	}
	// 添加问答题
	static addFill(data) {
		return http.post('/exam/admin/question_and_answer', data)
	}
	// 修改问答题
	static modifyFill(data) {
		return http.put('/exam/admin/question_and_answer', data)
	}
	// 删除问答题
	static deleteFill(id) {
		return http.delete(`/exam/admin/question_and_answer/${id}`)
	}
	// 获取所有判断题
	static getjudgeAll(pageNum) {
		return http.get(`/exam/admin/judges/page/${pageNum}`)
	}
	// 根据考试id获取所有的判断题
	static getJudgeId(examId) {
		return http.get(`/exam/admin/judges/exam/${examId}`)
	}
	// 添加判断题
	static addJudge(data) {
		return http.post('/exam/admin/judges', data)
	}
	// 修改判断题
	static modifyJudge(data) {
		return http.put('/exam/admin/judges', data)
	}
	// 删除判断题
	static deleteJudge(id) {
		return http.delete(`/exam/admin/judges/${id}`)
	}
	// 获取所有选段填空题
	static getFillInBlanksAll(pageNum) {
		return http.get(`/exam/admin/chooseSentences/page/${pageNum}`)
	}
	// 根据考试id获取所有的选段填空题
	static getFillInBlanksId(examId) {
		return http.get(`/exam/admin/chooseSentences/exam/${examId}`)
	}
	// 添加选段填空题
	static addFillInBlanks(data) {
		return http.post('/exam/admin/chooseSentences', data)
	}
	// 修改选段填空题
	static modifyFillInBlanks(data) {
		return http.put('/exam/admin/chooseSentences', data)
	}
	// 删除选段填空题
	static deleteFillInBlanks(id) {
		return http.delete(`/exam/admin/chooseSentences/${id}`)
	}
	// 获取所有选段符合题意题
	static getinLineWithQuestionAll(pageNum) {
		return http.get(`/exam/admin/textsCorrect/page/${pageNum}`)
	}
	// 根据考试id获取所有的符合题意题
	static getinLineWithQuestionId(examId) {
		return http.get(`/exam/admin/textsCorrect/exam/${examId}`)
	}
	// 添加符合题意题
	static addinLineWithQuestion(data) {
		return http.post('/exam/admin/textsCorrect', data)
	}
	// 修改符合题意题
	static modifyinLineWithQuestion(data) {
		return http.put('/exam/admin/textsCorrect', data)
	}
	// 删除符合题意题
	static deleteinLineWithQuestion(id) {
		return http.delete(`/exam/admin/textsCorrect/${id}`)
	}
	// 获取所有写作题
	static getWrite(pageNum) {
		return http.get(`/exam/admin/writing/page/${pageNum}`)
	}
	// 根据考试id获取所有的写作题
	static getWriteId(examId) {
		return http.get(`/exam/admin/writing/exam/${examId}`)
	}
	// 添加写作题  不带材料
	static addWriteQuestionNo(data) {
		return http.post('/exam/admin/writing', data)
	}
	// 添加写作题
	static addWriteQuestion(data) {
		return http.post('/exam/admin/materialWritings', data)
	}
	// 修改写作题 不带材料
	static modifyWriteQuestionNo(data) {
		return http.put('/exam/admin/writing', data)
	}
	// 修改写作题
	static modifyWriteQuestion(data) {
		return http.put('/exam/admin/textsCorrect', data)
	}
	// 删除写作题 不带材料
	static deleteWriteQuestionNo(examId, partId, id) {
		return http.delete(`/exam/admin/writing/${examId}/${partId}/${id}`)
	}
	// 删除写作题
	static deleteWriteQuestion(examId, partId, id) {
		return http.delete(`/exam/admin/materialWriting/${examId}/${partId}/${id}`)
	}
	// 获取所有文章
	static getArticleAll(pageNum) {
		return http.get(`/exam/admin/text/page/${pageNum}`)
	}
	// 根据考试id获取所有的文章
	static getArticleId(examId) {
		return http.get(`/exam/admin/text/exam/${examId}`)
	}
	// 添加文章
	static addArticle(data) {
		return http.post('/exam/admin/text', data)
	}
	// 修改文章
	static modifyArticle(data) {
		return http.put('/exam/admin/text', data)
	}
	// 删除文章
	static deleteArticle(id) {
		return http.delete(`/exam/admin/text/${id}`)
	}
	












	// 提交 给指定考试的指定Part添加题目 听力管理
	static async submitList(data = {}) {
		return await http.post('listening/question/add', data)
	}
	// 提交 给指定考试的指定Part添加题目 阅读管理
	static async submitRead(data = {}) {
		return await http.post('/reading/question/add', data)
	}
	// 提交 给指定考试的指定Part添加题目 写作管理
	static async submitWrite(data = {}) {
		return await http.post('/writing/question/add', data)
	}
	//提交 添加文章
	static async submitText(data = {}) {
		return await http.post('/reading/text/add', data)
	}
	// 获取登录二维码
	static async getCode() {
		return await http.get('/user/getQrCode')
	}
	// 获取登录状态
	static async getStatus(eventKey) {
		return await http.get(`/user/getLoginStatus/${eventKey}`)
	}
	// 退出
	static async layout() {
		return await http.get('/user/logout')
	}
	/**
	 * 学科引导
	 * @param {*} data 
	 * @returns 
	 */
	/* ++++++++++++++++++++++++++++++++++++++++++++++=考试种类+++++++++++++++++++++++++++++++++++++++++++++ */
	// 获取所有考试种类
	static getobtain(pageNum) {
		return http.get(`introductory/admin/exam/page/${pageNum}`)
	}
	// 获取某一个考试种类详情
	static getobtainDetail(examId) {
		return http.get(`introductory/admin/exam/${examId}`)
	}
	// 添加考试种类
	static newlyadded(data = {}) {
		return http.post('/introductory/admin/exam', data)
	}
	// 修改考试种类
	static newlyput(data = {}) {
		return http.put('/introductory/admin/exam', data)
	}
	/* ++++++++++++++++++++++++++++++++++++++++++++++=百科+++++++++++++++++++++++++++++++++++++++++++++ */
	// 新增百科
	static addGuide(data = {}) {
		return http.post('introductory/admin/introductory', data)
	}
	// 修改百科
	static modifyGuide(data = {}) {
		return http.put('introductory/admin/introductory', data)
	}
	不分页获取所有百科
	static getWik() {
		return http.get('introductory/admin/introductory/list')
	}
	// 分页获取百科list
	static getguide(pageNum) {
		return http.get(`introductory/admin/introductory/page/${pageNum}`)
	}

	// 获取某个考试下面的所有百科
	static getTestguide(examId, pageNum) {
		return http.get(`introductory/admin/introductory/exam/${examId}/page/${pageNum}`)
	}
	//获取某一个百科的详情内容
	static getWikipediaDetail(introductoryId) {
		return http.get(`introductory/admin/introductory/${introductoryId}`)
	}
	/* ++++++++++++++++++++++++++++++++++++++++++++++=百科章节+++++++++++++++++++++++++++++++++++++++++++++ */
	// 新增章节
	static addChapter(data = {}) {
		return http.post('introductory/admin/introductory/chapter', data)
	}
	// 修改章节
	static modifyChapter(data = {}) {
		return http.put('introductory/admin/introductory/chapter', data)
	}
	// 获取所有的章节
	static getChapterAll() {
		return http.get(`introductory/admin/introductory/chapter/list`)
	}
	// 分页获取所有的章节
	static getChapter(pageNum) {
		return http.get(`introductory/admin/introductory/chapter/page/${pageNum}`)
	}

	// 分页获取某个百科下面所有的章节
	static getWikipediaChapter(introductoryId, pageNum) {
		return http.get(`introductory/admin/introductory/${introductoryId}/chapter/page/${pageNum}`)
	}
	// 获取某一个章节的详情
	static getChapterDetail(chapterId) {
		return http.get(`introductory/admin/introductory/chapter/${chapterId}`)
	}

	/* ++++++++++++++++++++++++++++++++++++++++++++++=百科章节文章+++++++++++++++++++++++++++++++++++++++++++++ */
	// 分页获取所有的文章内容
	static getArticle(pageNum) {
		return http.get(`introductory/admin/introductory/chapter/detail/page/${pageNum}`)
	}

	// 分页获取某一个章节下的所有文章
	static getChapterArtice(chapterId, pageNum) {
		return http.get(`/introductory/admin/introductory/chapter/${chapterId}/detail/page/${pageNum}`)
	}

	// 获取百科文章详情
	static getArticeDetail(detailId) {
		return http.get(`introductory/admin/introductory/chapter/detail/${detailId}`)
	}
	// 修改文章内容
	static putRepairarticles(data) {
		return http.put('/introductory/admin/introductory/chapter/detail', data)
	}

	// 新增一篇文章
	static postNewarticles(data = {}) {
		return http.post('/introductory/admin/introductory/chapter/detail', data)
	}


	/**
	 * ================================================================================课程管理模块
	 * @param {*} pageNum 
	 * @returns 
	 */
	// 获取所有课程
	static getCourse(pageNum) {
		return http.get(`/course/admin/all/page/${pageNum}`)
	}
	// 不分页获取所有的课程info的Lis
	static getCourselist() {
		return http.get('/course/admin/all')
	}
	// 获取课程详情
	static getCourseDetail(courseId) {
		return http.get(`/course/admin/course/${courseId}`)
	}
	// 新增课程
	static addCourse(data = {}) {
		return http.post(`/course/admin`, data)
	}
	// 修改课程
	static modifyCourse(data = {}) {
		return http.put(`/course/admin`, data)
	}
	/*++++++++++++++++++++++++++++++++++++++++++++++++++++++课程章节++++++++++++++++++++++++++++++++++++++++++++++++++++++=*/
	// 获取某一课程所有章节
	static getSection(courseId, pageNum) {
		return http.get(`/course/admin/course/${courseId}/chapter/page/${pageNum}`)
	}
	// 不分页获取某一个课程的所有章节信息
	static getCourseSection(courseId) {
		return http.get(`/course/admin/all/chapter/${courseId}`)
	}
	// // 获取所有章节
	static getSectionAll(pageNum) {
		return http.get(`/course/admin/all/chapter/page/${pageNum}`)
	}
	// 获取章节详情
	static getSectionDetail(chapterId) {
		return http.get(`/course/admin/chapter/${chapterId}`)
	}
	// 新增章节
	static addSection(data = {}) {
		return http.post(`/course/admin/chapter`, data)
	}
	// 修改章节
	static modifySection(data = {}) {
		return http.put(`/course/admin/chapter`, data)
	}
	/*++++++++++++++++++++++++++++++++++++++++++++++++++++++章节子课程++++++++++++++++++++++++++++++++++++++++++++++++++++++=*/
	// 获取章节下的所有子课程
	static getSectionChildCourseAll(chapterId, pageNum) {
		return http.get(`/course/admin/chapter/${chapterId}/details/page/${pageNum}`)
	}
	// 获取所有的详情
	static getChildCourseAll(pageNum) {
		return http.get(`/course/admin/details/page/${pageNum}`)
	}
	// 获取子课程的详情
	static getChildCourseDetail(detailId) {
		return http.get(`/course/admin/detail/${detailId}`)
	}
	// 新增章节下的详情
	static addSectionDetail(data = {}) {
		return http.post(`/course/admin/detail`, data)
	}
	// 修改章节下的详情
	static modifySectionDetail(data = {}) {
		return http.put(`/course/admin/detail`, data)
	}

	/**
	 * =====================================================老师模块
	 * @param {*} pageNum 
	 * @returns 
	 */
	// 获取老师列表
	static getTeacher() {
		return http.get('/teacher/admin/all')
	}
	// 新增老师
	static addTeacher(data = {}) {
		return http.post(`/teacher/admin/add`, data)
	}
	// 修改老师
	static modifyTeacher(data = {}) {
		return http.put(`/teacher/admin/update`, data)
	}
	// 删除老师
	static delTeacher(teacherId) {
		return http.delete(`/teacher/admin/${teacherId}`)
	}

	// 刷新缓存获取最新的内容
	static getRefresh() {
		return http.get('/introductory/refresh')
	}
	/**===================================================常见问题=============================================== */
	// 分页获取所有的问题list
	static getQuestionAll(pageNum) {
		return http.get(`wiki/admin/list/page/${pageNum}`)
	}
	// 获取某一个问题详情
	static getQuestionDetail(wikiId) {
		return http.get(`wiki/admin/${wikiId}`)
	}
	// 更新某一个问题
	static modifyQuestion(data = {}) {
		return http.put('/wiki/admin', data)
	}
	// 添加一个新的问题
	static addQuestion(data = {}) {
		return http.post('/wiki/admin', data)
	}
	/**获取token */

	static getQiniuToken() {
		return http.get('/file/getToken')
	}
	/**=======================================================商品管理=============================================== */
	// 分页获取spu的信息
	static getCommodityspu(pageNum) {
		return http.get(`/product/admin/spu/page/${pageNum}`)
	}
	// 分页获取sku的信息
	static getCommoditysku(pageNum) {
		return http.get(`/product/admin/sku/page/${pageNum}`)
	}
	// 根据spu的id获取某个spu下的sku
	static getCommodityskuId(spuId) {
		return http.get(`/product/admin/spu/sku/${spuId}`)
	}
	// 获取某个spu的详细信息
	static getCommodityspuletter(spuId) {
		return http.get(`/product/admin/spu/${spuId}`)
	}
	// 修改某个spu的属性
	static putCommodityspugenus(data = {}) {
		return http.put('/product/admin/spu', data)
	}
	// 新增一个spu商品
	static getCommodityspuplus(data = {}) {
		return http.post('/product/admin/spu', data)
	}
	// 获取某个sku的详情
	static getCommodityskuletter(skuId) {
		return http.get(`/product/admin/sku/${skuId}`)
	}
	// 修改某个sku的属性
	static modifyCommodityskugenus(data = {}) {
		return http.put('/product/admin/sku', data)
	}
	// 新增一个sku对应的内容
	static addCommodityskuplus(data = {}) {
		return http.post('/product/admin/sku', data)
	}


	/**========================================================活动管理================================================= */
	//分页获取所有的活动list
	static getActivitylist(pageNum) {
		return http.get(`/activity/admin/activity/page/${pageNum}`)
	}
	// 获取某个活动详情
	static getActivitydetails(activityId) {
		return http.get(`/activity/admin/activity/${activityId}`)
	}
	// 保存某一个活动
	static postActivity(data = {}) {
		return http.post('/activity/admin/activity', data)
	}
	// 更新某一个活动
	static putActivity(data = {}) {
		return http.put('/activity/admin/activity', data)
	}
	// 查询某一个活动下面所有的关于价格的活动内容
	static getPromotionlist(activityId) {
		return http.get(`/activity/admin/activity/${activityId}/price`)
	}
	// 查询一个关于价格的活动内容
	static getPromotiondetails(id) {
		return http.get(`/activity/admin/activity/price/${id}`)
	}
	// 保存一个价格促销活动
	static postPromotion(data = {}) {
		return http.post('/activity/admin/activity/price', data)
	}
	// 更新一个价格促销活动
	static putPromotion(data = {}) {
		return http.put('/activity/admin/activity/price', data)
	}
	/**=============================================================优惠券=================================================== */
	// 获取某一个优惠卷的详细信息
	static getCoupondetails(couponId) {
		return http.get(`/coupon/admin/coupon/${couponId}`)
	}
	// 修改某个优惠卷的信息
	static putCouponletter(data = {}) {
		return http.put('/coupon/admin/coupon', data)
	}
	// 新增一个优惠卷
	static postCouponletter(data) {
		return http.post('/coupon/admin/coupon', data)
	}
	// 分页获取目前所有的优惠卷
	static getCouponlist(pageNum) {
		return http.get(`/coupon/admin/coupon/page/${pageNum}`)
	}
	// 分页获取用户当前的优惠卷信息
	static getUserCouponlist(pageNum) {
		return http.get(`/coupon/admin/user_coupon/page/${pageNum}`)
	}
	// 获取某个用户的某个优惠卷信息
	static getUserCoupondetails(id) {
		return http.get(`/coupon/admin/user_coupon/${id}`)
	}
	// 修改某一个用户的优惠卷的详细信息
	static putUserCoupon(data = {}) {
		return http.put('/coupon/admin/user_coupon', data)
	}
	// 给某一个用户新增一个优惠卷
	static postUserCoupon(data = {}) {
		return http.post('/coupon/admin/user_coupon', data)
	}

	// =================================================================订单管理
	static getOrderList(state, type, pageNum) {
		return http.get(`/order/admin/state/${state}/type/${type}/page/${pageNum}`)
	}
	// 不分页获取优惠卷列表
	static getCoupon() {
		return http.get('/coupon/admin/all/coupon/')

	}
	/**
	 * =================================================用户管理=================================================
	 */
	// 分页获取所有用户
	static getUserList(pageNum) {
		return http.get(`/user/admin/page/${pageNum}`)
	}
	// 不分页获取所有用户
	static getUserAll() {
		return http.get(`/user/admin/list`)
	}
	// 根据用户id获取用户信息
	static getUserId(id) {
		return http.get(`/user/admin/${id}`)
	}
	// search
	static search(type, value) {
		return http.get(`/user/admin/search/${type}/${value}`)
	}
	// 修改用户
	static modifyUser(data) {
		return http.put(`/user/admin/info`, data)
	}
	// 删除用户
	static delUser(id) {
		return http.delete(`/user/admin/${id}`)
	}
	/* ++++++++++++++++++++++++++++++++++++++++++++++=写作管理+++++++++++++++++++++++++++++++++++++++++++++ */
	//写作管理解析录入
	static addParse(data) {
		return http.post(`/writing/analysis/admin/add`, data)
	}
	static compositionList(cate) {
		return http.get(`/writing/topic/list/${cate}`)
	}
	//获取解析list
	static getAnalysisList(pageNum) {
		return http.get(`/writing/analysis/list/${pageNum}`)
	}
	//获取某一个解析
	static getAnalysis(analysisId) {
		return http.get(`/writing/analysis/${analysisId}`)
	}
	// 添加一个新解析
	static addAnalysis(data) {
		return http.post(`/writing/analysis`, data)
	}
	// 删除一个解析
	static delAnalysis(id) {
		return http.delete(`/writing/analysis/${id}`)
	}
	// 修改一个解析
	static putAnalysis(data) {
		return http.put(`/writing/analysis`,data)
	}
	//获取观点解析list
	static getViewList(pageNum) {
		return http.get(`/writing/view/list/${pageNum}`)
	}
	//添加一个新的观点
	static addView(data) {
		return http.post(`/writing/view`, data)
	}
	// 删除一个观点
	static delView(id) {
		return http.delete(`/writing/view/${id}`)
	}
	// 修改一个观点
	static putView(data) {
		return http.put(`/writing/view`,data)
	}
	//获取某一个观点
	static getView(id) {
		
		return http.get(`/writing/view/${id}`)
	}
	//获取学生范文list
	static getEssaysList(pageNum) {
		return http.get(`/writing/essays/list/${pageNum}`)
	}
	// 删除学生范文
	static delEssays(id) {
		return http.delete(`/writing/essays/${id}`)
	}
	// 修改范学生范文
	static putEssays(data) {
		return http.put(`/writing/essays`,data)
	}
	//获取某一个学生范文
	static getEssays(id) {
		
		return http.get(`/writing/essays/${id}`)
	}
	//添加一个新的学生范文解析
	static addEssays(data) {
		return http.post(`/writing/essays`, data)
	}
	//获取范文解析list
	static getEssayAnalysisList(pageNum) {
		return http.get(`/writing/essayAnalysis/list/${pageNum}`)
	}
	//获取某一个范文解析
	static getEssayAnalysis(id) {
		
		return http.get(`/writing/essayAnalysis/${id}`)
	}
	// 删除范文解析
	static delEssayAnalysis(id) {
		return http.delete(`/writing/essayAnalysis/${id}`)
	}
	// 修改范文解析
	static putEssayAnalysis(data) {
		return http.put(`/writing/essayAnalysis`,data)
	}
	//添加一个新的范文解析
	static addEssayAnalysis(data) {
		return http.post(`/writing/essayAnalysis`, data)
	}
	//获取所以的观点
	static getAllviewlist() {
		return http.get(`/writing/view/list`)
	}
	//获取所以的f范文列表
	static getAllessayslist() {
		return http.get(`/writing/essays/list`)
	}
	//获取所以的解析列表
	static getAllessayAnalysis() {
		return http.get(`/writing/essayAnalysis/list`)
	}
	
	/* ++++++++++++++++++++++++++++++++++++++++++++++=权限管理+++++++++++++++++++++++++++++++++++++++++++++ */
	//获取权限list
	static getAuthList() {
		return http.get(`/rbac/auth/list`)
	}
	//添加一个新的权限
	static addAuth(data) {
		return http.post(`/rbac/auth`, data)
	}
	// 修改权限
	static putAuth(data) {
		return http.put(`/rbac/auth`,data)
	}
	//获取某一个权限详情
	static getAuth(id) {
		
		return http.get(`/rbac/auth/${id}`)
	}
	// 删除某一个权限
	static delAuth(id) {
		return http.delete(`/rbac/auth/${id}`)
	}
	/* ++++++++++++++++++++++++++++++++++++++++++++++=角色管理+++++++++++++++++++++++++++++++++++++++++++++ */
	//获取角色list
	static getRoleList() {
		return http.get(`/rbac/role/list`)
	}
	//添加一个新的角色信息
	static addRole(data) {
		return http.post(`/rbac/role`, data)
	}
	// 修改角色信息
	static putRole(data) {
		return http.put(`/rbac/role`,data)
	}
	//获取某一个角色信息详情
	static getRole(id) {
		
		return http.get(`/rbac/role/${id}`)
	}
	//更新数据
	static getWriteRefresh() {
		
		return http.get(`https://writing.langsi.online/mini/writing/refresh`)
	}
	// 删除某一个角色信息
	static delRole(id) {
		return http.delete(`/rbac/role/${id}`)
	}
	/* ++++++++++++++++++++++++++++++++++++++++++++++=口语小程序+++++++++++++++++++++++++++++++++++++++++++++ */
	//获取串题答案分页list
	static getChuantiAnswerPage(pageNum) {
		return http.get(`/speaking/answer/list/${pageNum}`)
	}
	//获取串题答案list
	static getChuantiAnswerList() {
		return http.get(`/speaking/answer/list`)
	}
	//添加一个新的part1-4串题答案
	static addChuantiPart14Answer(data) {
		return http.post(`/speaking/answer/part1-4`, data)
	}
	//添加一个新的part2串题答案
	static addChuantiPart2Answer(data) {
		return http.post(`/speaking/answer/part2`, data)
	}
	//添加一个新的part3串题答案
	static addChuantiPart3Answer(data) {
		return http.post(`/speaking/answer/part3`, data)
	}
	// 修改part1-4串题答案
	static putChuantiiPart14Answer(data) {
		return http.put(`/speaking/answer/part1-4`,data)
	}
	// 修改part2串题答案
	static putChuantiiPart2Answer(data) {
		return http.put(`/speaking/answer/part2`,data)
	}
	// 修改part3串题答案
	static putChuantiiPart3Answer(data) {
		return http.put(`/speaking/answer/part3`,data)
	}
	//获取某一个串题答案
	static getChuantiAnswer(id) {
		
		return http.get(`/speaking/answer/${id}`)
	}
	// 删除某一个串题答案
	static delChuantiAnswer(id) {
		return http.delete(`/speaking/answer/${id}`)
	}

	//获取题目详情分页list
	static getTopicDetails(part,examType,pageNum) {
		return http.get(`/speaking/topic/list/part/${part}/examType/${examType}/pageNum/${pageNum}`)
	}
	//获取题目详情list
	static getTopicDetailList(part,examType) {
		return http.get(`/speaking/topic/list/part/${part}/examType/${examType}`)
	}
	//添加一个新的题目详情
	static addTopicDetail(data) {
		return http.post(`/speaking/topic`, data)
	}
	// 修改题目详情
	static putTopicDetail(data) {
		return http.put(`/speaking/topic`,data)
	}
	//获取某一个题目详情
	static getTopicDetail(id) {
		
		return http.get(`/speaking/topic/${id}`)
	}
	// 删除某一个题目详情
	static delTopicDetail(id) {
		return http.delete(`/speaking/topic/${id}`)
	}

	//获取串题思路分页list
	static getChuantiThinkPage(pageNum) {
		return http.get(`/speaking/idea/list/pageNum/${pageNum}`)
	}
	//获取串题思路list
	static getChuantiThinkList() {
		return http.get(`/speaking/idea/list`)
	}
	//添加一个新的串题思路
	static addChuantiThink(data) {
		return http.post(`/speaking/idea`, data)
	}
	// 修改串题思路
	static putChuantiThink(data) {
		return http.put(`/speaking/idea`,data)
	}
	//获取某一个串题思路
	static getChuantiThink(id) {
		
		return http.get(`/speaking/idea/${id}`)
	}
	// 删除某一个串题思路
	static delChuantiThink(id) {
		return http.delete(`/speaking/idea/${id}`)
	}


	//获取音频答案分页list
	static getTopicRecordPage(pageNum) {
		return http.get(`/speaking/topic/record/list/${pageNum}`)
	}
	//获取音频答案list
	static getTopicRecordList() {
		return http.get(`/speaking/topic/record/list`)
	}
	//添加一个新的音频答案
	static addTopicRecord(data) {
		return http.post(`/speaking/topic/record`, data)
	}
	// 修改音频答案
	static putTopicRecord(data) {
		return http.put(`/speaking/topic/record`,data)
	}
	//获取某一个音频答案
	static getTopicRecord(id) {
		
		return http.get(`/speaking/topic/record/${id}`)
	}
	// 删除某一个音频答案
	static delTopicRecord(id) {
		return http.delete(`/speaking/topic/record/${id}`)
	}
	/* ++++++++++++++++++++++++++++++++++++++++++++++=拼团模块+++++++++++++++++++++++++++++++++++++++++++++ */

	//获取平团活动list
	static getGroupBuyingList(key) {
		
		return http.get(`/group-buying/activity/key/${key}`)
	}
	//关键字查询分页List
	static getGroupBuyingKeyList(pageNum,key) {
		
		return http.get(`/group-buying/activity/pageNum/${pageNum}/key/${key}`)
	}
	//获取平团活动详情
	static getGroupBuying(id) {
		
		return http.get(`/group-buying/activity/${id}`)
	}
	//添加一个新的拼团活动
	static addGroupBuying(data) {
		return http.post(`/group-buying/activity`, data)
	}
	//修改一个新的拼团活动
	static putdGroupBuying(data) {
		return http.put(`/group-buying/activity`, data)
	}
	//获取所以用户已经拼团的List
	static getAllGroupBoughtList(key) {
		
		return http.get(`/group-buying/group/key/${key}`)
	}
	//获取所以用户已经拼团的分页List
	static getAllGroupBoughtListPage(pageNum,key) {
		
		return http.get(`/group-buying/group/pageNum/${pageNum}/key/${key}`)
	}
	//获取所以用户已经拼团的详情
	static getAllGroupBought(id) {
		
		return http.get(`/group-buying/group/${id}`)
	}
	/* ++++++++++++++++++++++++++++++++++++++++++++++=资源模块+++++++++++++++++++++++++++++++++++++++++++++ */
	//获取资源list
	static getResourceList(pageNum,key) {
		
		return http.get(`/resource/list/key/${key}/pageNum/${pageNum}`)
	}
	//获取资源详情
	static getResource(id) {
		
		return http.get(`/resource/${id}`)
	}
	//添加一个新的资源详情
	static addResource(data) {
		return http.post(`/resource`, data)
	}
	//修改一个新的资源详情
	static putResource(data) {
		return http.put(`/resource`, data)
	}
		//获取资源详情
	static delResource(id) {
		
		return http.delete(`/resource/${id}`)
	}
}