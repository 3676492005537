import Vue from 'vue'
import App from './App.vue'
// 引入公共样式
import '@/assets/less/index.less'
import '@/assets/css/common.css'

// Element全局引入
// import Element from 'element-ui'
// Element按需引入
import {
  Button,
  Radio,
  Container,
  Aside,
  Header,
  Main,
  Menu,
  Submenu,
  MenuItemGroup,
  MenuItem,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Row,
  Card,
  Col,
  Table,
  TableColumn,
  Breadcrumb,
  BreadcrumbItem,
  Tag,
  Form,
  FormItem,
  Input,
  Select,
  Switch,
  DatePicker,
  TimePicker,
  Option,
  Dialog,
  Pagination,
  MessageBox,
  Message,
  RadioGroup,
  RadioButton,
  Upload,
  Slider,
  InputNumber,
  Checkbox,
  CheckboxGroup,
  Scrollbar,
  Autocomplete,
  ColorPicker,
  Tabs,
  TabPane,
  Descriptions,
  DescriptionsItem

} from 'element-ui'
// 引入element样式文件
import 'element-ui/lib/theme-chalk/index.css';

// 引入router文件
import router from './router'
import filter from './utils/filters.js'
// 引入vuex状态管理定义的文件
import store from "./store"

// 引入axios
import axios from 'axios'
import Api from "./api/data";
import Http from "./api/axios";
// 引入mock模拟接口返回
import './api/mock.js'

// 引入axios不是一个插件 全局使用 需要绑定在 vue的prototype属性上
Vue.prototype.$axios = axios
Vue.prototype.$api = Api;
Vue.prototype.$Http = Http;
// 全局注入element
// Vue.use(Element)
// 按需注入element
Vue.use(DescriptionsItem)
Vue.use(Descriptions)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Button)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Radio)
Vue.use(Container)
Vue.use(Aside)
Vue.use(Header)
Vue.use(Main)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(MenuItem)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Row)
Vue.use(Card)
Vue.use(Col)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Tag)
Vue.use(ColorPicker)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Select)
Vue.use(Switch)
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(Option)
Vue.use(Dialog)
Vue.use(Pagination)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Upload)
Vue.use(Slider)
Vue.use(InputNumber)
Vue.use(Scrollbar)
Vue.use(Autocomplete)
// import VueQuillEditor from 'vue-quill-editor'
import  VueQuillEditor from 'vue-quill-editor' //vue-quill-editor其它文件可在应用页面直接引入

Vue.use(VueQuillEditor)
// 引入样式
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
// let fontSizeStyle = Quill.import('attributors/style/size') //引入这个后会把样式写在style上
// fontSizeStyle.whitelist = ['45px', '60px', '90px']
// Quill.register(fontSizeStyle, true)
// Vue.use(VueQuillEditor, /* { 默认全局 } */)

Vue.config.productionTip = false
// 弹窗
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$message = Message
// 过滤器
Vue.filter('formatDate', filter.formatDate)
Vue.filter('publisher', filter.publisher)

new Vue({
  router,//注册router文件
  store,//注册vuex的store文件
  render: h => h(App),
}).$mount('#app')
