// vuex状态管理

// 引入vue
import Vue from 'vue'
// 引入vuex
import Vuex from 'vuex'

// 定义模块
import tab from './tab'

// 全局注入vuex
Vue.use(Vuex)

// 创建vuex实例
const store = new Vuex.Store({
    modules:{ //通过modules的方式将不同模块进行一个引入
        tab
    }
})

// 对外进行暴露
export default store